<template>
<section id="nosotros-ayudamos">
	<div class="container jumbotron">
		<div class="row justify-content-center">
			<div class="col-10">
				<h2 class="section-title">APPARENT FAILURE OF GRUPEER PLATFORM - MARCH 2020</h2>
				<p>
					On March 31st 2020 Grupeer published an article on their blog saying: "Regretfully, we are forced to inform you that in the current circumstances it is not possible for us to continue operations as before with an objective cause out of our control. Therefore, we hereby notify you that all payments to investors of Grupeer are currently suspended due to emergency state declared in European Union and worldwide regarding COVID-19 pandemic crises."
				</p>
				<br>
				<p>
					The statement goes on to say: "Therefore, neither borrowers nor Grupeer are able to fulfil obligations towards you, our investors. <a href="https://www.grupeer.com/blog/statement-from-grupeer/">https://www.grupeer.com/blog/statement-from-grupeer/</a>
				</p>
				<br>
				<p>
					Ibancar would like to rectify this statement. Although we cannot speak for other originators, Ibancar has never failed to fulfill its obligations to any of it's investors and has never missed any debt payments. Ibancar remains strong and expanding business, our loan book is efficiently and conservatively collateralized against borrowers cars at an average LTV of 39% and, as a result of this collateralization, the recovery rate on defaulted Ibancar loans over the last 5 years has been in excess of 100%. This exceptional performance means that Ibancar loans are, and will continue to be, amongst the safest P2P/Crowdlending investments available in the market.
				</p>
				<br>
				<p>
					We can also communicate that we have been unable to contact anyone at Grupeer and that we have no information beyond what they have made public. 
					We sincerely hope that Grupeer can resolve it's internal problems quickly or take immediate steps to prevent any loss of investor capital.
				</p>
				<p>
					The last funding Ibancar received from Grupeer was on 08/03/2020, any money that has not been amortized and is still invested with Ibancar is safe and will not be redeemed to Grupeer until we have assurances that this money will reach investors or a liquidator is appointed.
				</p>
				<br>
				<p>
					Ibancar continues to work successfully with our partners IUVO, BONDSTER, and VIVENTOR with whom we are in regular contact. Our medium term plans have not changed and we intend to continue preparing for the launch of Ibancar loans on other P2P / Crowdlending platforms in order to meet strong investor demand for our low risk loans.
				</p>
				<br>
			</div><!-- /row1 -->
		</div><!-- /container -->
	</div>
</section><!-- /section -->

</template>

<script>
export default {
    name: 'GrupperPlatform',
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="scss">
.jumbotron {
	padding: 25px 0 0 25px;
	margin-top: 90px;
    background-color: #fff;
}

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {

}

@include media-breakpoint-between(md,lg) {

}

@include media-breakpoint-between(xl,xxl) {

}
</style>